import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/marginX_Logo-Dark.svg'
import Icon from '../../assets/icon.svg'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { useDarkModeManager } from '../../contexts/LocalStorage'
import MenuPopup from '../Menu'
import Toggle from '../Toggle'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 999;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const StaticIcon = styled(Link)`
  display: flex;
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }
`

const ToggleWrapper = styled.div`
  margin-bottom: -5px;
`


function Title() {
  // console.log(history)
  const history = useHistory()
  const below1080 = useMedia('(max-width: 1080px)')
  const below800 = useMedia('(max-width: 800px)')
  const [isDark, toggleDarkMode] = useDarkModeManager()


  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <RowFixed>
          {below1080 && (
            <MenuPopup />
          )}
          {below800 ?
            <StaticIcon onClick={() => history.push('/')}><img width={'34px'} src={Icon} alt="logo" /></StaticIcon>
            : below1080 ?
              <StaticIcon onClick={() => history.push('/')}><img width={'120px'} src={Logo} alt="logo" /></StaticIcon>
              :
              <UniIcon id="link" onClick={() => history.push('/')}>
                <img width={'c'} src={Logo} alt="logo" />
              </UniIcon>
          }
          {/* {!below1080 && (
            <img width={'84px'} style={{ marginLeft: '8px', marginTop: '0px' }} src={Wordmark} alt="logo" />
          )} */}
        </RowFixed>
        {below1080 && (
          <RowFixed>
            <ToggleWrapper><Toggle isActive={isDark} toggle={toggleDarkMode} /></ToggleWrapper>
          </RowFixed>
        )}
      </Flex>
    </TitleWrapper>
  )
}

export default Title