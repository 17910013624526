import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { AutoColumn } from '../Column'
import Title from '../Title'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { transparentize } from 'polished'
import { TYPE } from '../../Theme'
import { withRouter } from 'react-router-dom'
import { TrendingUp, List, PieChart, Disc, Pocket, Hash, RefreshCw, Gift, Droplet } from 'react-feather'
import Link from '../Link'
import { useSessionStart } from '../../contexts/Application'
import { useDarkModeManager, useChainIdManager } from '../../contexts/LocalStorage'
import Toggle from '../Toggle'

const Wrapper = styled.div`
  height: ${({ isMobile }) => (isMobile ? 'initial' : '100vh')};
  background-color: ${({ theme }) => transparentize(0.4, theme.bg1)};
  color: ${({ theme }) => theme.text1};
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  position: sticky;
  top: 0px;
  z-index: 10000;
  box-sizing: border-box;
  /* background-color: #1b1c22; */
  background: linear-gradient(193.68deg, #1b1c22 0.68%, #000000 100.48%);
  color: ${({ theme }) => theme.bg2};

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    position: relative;
  }

  @media screen and (max-width: 600px) {
    padding: 1rem;
  }
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  :hover {
    opacity: 1;
  }
`

const DesktopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`

const MobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.div`
  margin-right: 0.75rem;
  font-size: 0.825rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  opacity: 0.8;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`

const Polling = styled.div`
  position: fixed;
  display: flex;
  left: 0;
  bottom: 0;
  padding: 1rem;
  color: white;
  opacity: 0.4;
  transition: opacity 0.25s ease;
  :hover {
    opacity: 1;
  }
`
const PollingDot = styled.div`
  width: 8px;
  height: 8px;
  min-height: 8px;
  min-width: 8px;
  margin-right: 0.5rem;
  margin-top: 3px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.green1};
`

function SideNav({ history }) {
  const below1080 = useMedia('(max-width: 1080px)')

  const below1180 = useMedia('(max-width: 1180px)')

  const seconds = useSessionStart()

  const [isDark, toggleDarkMode] = useDarkModeManager()

  return (
    <Wrapper isMobile={below1080}>
      {!below1080 ? (
        <DesktopWrapper>
          <AutoColumn gap="1rem" style={{ marginLeft: '.75rem', marginTop: '1.5rem' }}>
            <Title />
            {!below1080 && (
              <AutoColumn gap="1rem" style={{ marginTop: '0rem' }}>

                <BasicLink to="/home">
                  <Option activeText={history.location.pathname === '/home' ?? undefined}>
                    <TrendingUp size={18} style={{ marginRight: '.75rem' }} />
                    Overview
                  </Option>
                </BasicLink>
                <Link href="https://swap.marginx.io/" target="_blank">
                  <Option activeText={undefined}>
                    <RefreshCw size={18} style={{ marginRight: '.75rem' }} />
                    Swap
                  </Option>
                </Link>
                <BasicLink to="/tokens">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'tokens' ||
                        history.location.pathname.split('/')[1] === 'token') ??
                      undefined
                    }
                  >
                    <Disc size={18} style={{ marginRight: '.75rem' }} />
                    Tokens
                  </Option>
                </BasicLink>
                <BasicLink to="/pairs">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'pairs' ||
                        history.location.pathname.split('/')[1] === 'pair') ??
                      undefined
                    }
                  >
                    <PieChart size={18} style={{ marginRight: '.75rem' }} />
                    Pairs
                  </Option>
                </BasicLink>
                <BasicLink to="/transactions">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'transactions' ||
                        history.location.pathname.split('/')[1] === 'transactions') ??
                      undefined
                    }
                  >
                    <List size={18} style={{ marginRight: '.75rem' }} />
                    Transactions
                  </Option>
                </BasicLink>
                <BasicLink to="/accounts">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'accounts' ||
                        history.location.pathname.split('/')[1] === 'account') ??
                      undefined
                    }
                  >
                    <Pocket size={18} style={{ marginRight: '.75rem' }} />
                    Accounts
                  </Option>
                </BasicLink>
                <BasicLink to="/points">
                  <Option
                    activeText={
                      (history.location.pathname.split('/')[1] === 'points' ||
                        history.location.pathname.split('/')[1] === 'point') ??
                      undefined
                    }
                  >
                    <Hash size={18} style={{ marginRight: '.75rem' }} />
                    Points
                  </Option>
                </BasicLink>
                <Link href="https://swap.marginx.io/#/pool" target="_blank">
                  <Option activeText={undefined}>
                    <Droplet size={18} style={{ marginRight: '.75rem' }} />
                    Pool
                  </Option>
                </Link>
                <Link href="https://swap.marginx.io/#/farm" target="_blank">
                  <Option activeText={undefined}>
                    <Gift size={18} style={{ marginRight: '.75rem' }} />
                    Farm
                  </Option>
                </Link>
              </AutoColumn>
            )}
          </AutoColumn>
          <AutoColumn gap="0.5rem" style={{ marginLeft: '.75rem', marginBottom: '3rem' }}>
            <HeaderText>
              <Link href="https://marginx.io/" target="_blank">
                MarginX
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://deploy.marginx.io/" target="_blank">
                TokenFactory
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://marginxlabs.gitbook.io/product-docs/" target="_blank">
                Docs
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://pundiscan.io/fxbridge" target="_blank">
                Bridge
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://pundiscan.io/" target="_blank">
                PundiScan
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://www.geckoterminal.com/fx/fx_swap/pools" target="_blank">
                Gecko Terminal
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://twitter.com/marginx_io" target="_blank">
                Twitter
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://discord.com/invite/juEUMWsasQ" target="_blank">
                Discord
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://t.me/+GiT9MPWQaPU2ZGU9" target="_blank">
                Telegram
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://www.youtube.com/channel/UCJ8ggaUjfS5at6_toTJM8yg" target="_blank">
                YouTube
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://blog.marginx.io/" target="_blank">
                Medium
              </Link>
            </HeaderText>
            <HeaderText>
              <Link href="https://marginx.io/terms" target="_blank">
                Term of Use
              </Link>
            </HeaderText>
            <div style={{ marginTop: '0.2rem' }}></div>
            <Toggle isActive={isDark} toggle={toggleDarkMode} />
          </AutoColumn>
          {!below1180 && (
            <Polling style={{ marginLeft: '.5rem' }}>
              <PollingDot />
              <a href="/" style={{ color: 'white' }}>
                <TYPE.small color={'white'}>
                  Updated {!!seconds ? seconds + 's' : '-'} ago <br />
                </TYPE.small>
              </a>
            </Polling>
          )}
        </DesktopWrapper>
      ) : (
        <MobileWrapper>
          <Title></Title>
        </MobileWrapper>
      )}
    </Wrapper>
  )
}

export default withRouter(SideNav)
