import React, { useCallback, useEffect, useState } from 'react'
import { Menu, TrendingUp, List, PieChart, Disc, Pocket, Hash, RefreshCw, Gift, Droplet } from 'react-feather'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'
import { AutoColumn } from '../Column'
import Row, { RowBetween } from '../Row'
import { TYPE } from '../../Theme'
import Link, { BasicLink } from '../Link'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-radius: 36px;
  background-color: transparent;
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const TooltipContainer = styled.div`
  width: 150px;
  line-height: 150%;
  font-weight: 500;
  font-size: 1rem;
  background-color: #21252a;
  border-radius: 8px;
  padding: 5px 0px 5px;
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  height: 26px;
  color: white;
  display: flex;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  padding: 7px 0px 7px 15px;
  align-items: center;
  :hover {
    opacity: 0.8;
    background-color: #2C2F36;
  }
`

const HeaderText = styled.div`
  padding: 0px 0px 0px 15px;
  font-size: 0.7rem;
  font-weight: 500;
  display: inline-box;
  display: -webkit-inline-box;
  color: white;
  opacity: 0.6;
  :hover {
    opacity: 1;
  }
  a {
    color: ${({ theme }) => theme.white};
  }
`


export function Tooltip({ close, ...rest }) {
    const history = useHistory()
    useEffect(() => {
        close()
    }, [history.location.pathname])
    return (
        <Popover content={
            <TooltipContainer>
                <AutoColumn>
                    <BasicLink to="/home">
                        <Option activeText={history.location.pathname === '/home' ?? undefined}>
                            <TrendingUp size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Overview</TYPE.body>
                        </Option>
                    </BasicLink>
                    <Link href="https://swap.marginx.io/" target="_blank">
                        <Option activeText={undefined}>
                            <RefreshCw size={14} style={{ marginRight: '.5rem' }} />
                            Swap
                        </Option>
                    </Link>
                    <BasicLink to="/tokens">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'tokens' ||
                                    history.location.pathname.split('/')[1] === 'token') ??
                                undefined
                            }
                        >
                            <Disc size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Tokens</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/pairs">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'pairs' ||
                                    history.location.pathname.split('/')[1] === 'pair') ??
                                undefined
                            }
                        >
                            <PieChart size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Pairs</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/transactions">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'transactions' ||
                                    history.location.pathname.split('/')[1] === 'transactions') ??
                                undefined
                            }
                        >
                            <List size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Transactions</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/accounts">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'accounts' ||
                                    history.location.pathname.split('/')[1] === 'account') ??
                                undefined
                            }
                        >
                            <Pocket size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Accounts</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/points">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'points' ||
                                    history.location.pathname.split('/')[1] === 'points') ??
                                undefined
                            }
                        >
                            <Hash size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Points</TYPE.body>
                        </Option>
                    </BasicLink>
                    <Link href="https://swap.marginx.io/#/pool" target="_blank">
                        <Option activeText={undefined}>
                            <Droplet size={14} style={{ marginRight: '.5rem' }} />
                            Pool
                        </Option>
                    </Link>
                    <Link href="https://swap.marginx.io/#/farm" target="_blank">
                        <Option activeText={undefined}>
                            <Gift size={14} style={{ marginRight: '.5rem' }} />
                            Farm
                        </Option>
                    </Link>
                    <p style={{ borderTop: '1px solid #9c9c9c', width: '80%', margin: '3px auto', marginBottom: '8px' }}></p>
                    <HeaderText>
                        <Link href="https://marginx.io/" target="_blank">
                            MarginX
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://deploy.marginx.io/" target="_blank">
                            TokenFactory
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://marginxlabs.gitbook.io/product-docs/" target="_blank">
                            Docs
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://pundiscan.io/fxbridge" target="_blank">
                            Bridge
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://pundiscan.io/" target="_blank">
                            PundiScan
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://www.geckoterminal.com/fx/fx_swap/pools" target="_blank">
                            Gecko Terminal
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://twitter.com/marginx_io" target="_blank">
                            Twitter
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://discord.com/invite/juEUMWsasQ" target="_blank">
                            Discord
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://t.me/+GiT9MPWQaPU2ZGU9" target="_blank">
                            Telegram
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://www.youtube.com/channel/UCJ8ggaUjfS5at6_toTJM8yg" target="_blank">
                            YouTube
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://blog.marginx.io/" target="_blank">
                            Medium
                        </Link>
                    </HeaderText>
                    <HeaderText>
                        <Link href="https://marginx.io/terms" target="_blank">
                            Term of Use
                        </Link>
                    </HeaderText>
                </AutoColumn>
            </TooltipContainer>
        } {...rest} />
    )
}
// <Menu size={24} color='white' style={{ marginRight: '1rem', marginLeft: '0px' }} />
export default function MenuPopup({ text, disabled }) {
    const [show, setShow] = useState(false)

    const open = useCallback(() => setShow(true), [setShow])
    const close = useCallback(() => setShow(false), [setShow])

    return (
        <span style={{ marginLeft: '2px', marginRight: '0.3rem', width: '35px', height: '40px', marginBottom: '-10px' }} onMouseEnter={open} onMouseLeave={close} onTouchStart={open}>
            <Tooltip close={close} show={show && !disabled} placement='bottom'>
                <QuestionWrapper>
                    <Menu size={24} color='white' style={{ marginLeft: '0px' }} />
                </QuestionWrapper>
            </Tooltip>
        </span>
    )
}
